import * as React from "react"
import SEO from "../components/SEO/SEO";
import {Layout} from "../components/layout/layout";
import {Quote} from "../components/quote/quote";
import {TextBox} from "../components/text-box/text-box";
import {Gallery} from "../components/gallery/gallery";

import "./about.scss";

import img1 from "../images/gallery-1.jpg";
import img2 from "../images/gallery-2.jpg";
import img3 from "../images/gallery-3.jpg";
import img4 from "../images/gallery-4.jpg";
import img5 from "../images/gallery-5.jpg";

import {Arrow} from "../components/arrow/arrow";
import {IG_URL} from "../utils/constants";

const images = [
  img1,
  img2,
  img3,
  img4,
  img5,
];

const AboutPage = () => {
  return (
    <main className="my-about">
      <SEO
        title="About Us"
        pathname="/about"
        description="With a young group of sailors, captains, crew and technicians coming from diverse marine business and professional backgrounds, we are your one stop solution to all your yachting needs."
      />
      <Layout page="about">
        <Quote
          text="Our passion is yachting and our mission is to help others"
          highlightedText="enjoy the yachting lifestyle."
        />
        <Arrow alignBottom={false} url="about-1" />
        <TextBox
          id="about-1"
          text="With a young group of sailors, captains, crew and technicians coming from diverse marine business and professional backgrounds, we are your one stop solution to all your yachting needs."
          secondary=" Whether you are looking for an unforgettable boating experience, making the step towards ownership, doing improvements, maintenance, hiring professional crew or a trusted partner to take care of your vessel; we, the Miami Yachting family, will stand on your side and make it a reality."
        />
        <Arrow url="about-2"  />
        <Quote
          id="about-2"
          author="Capt. Randy A. Gomez"
          text="Clients come to Miami Yachting because they know we have always the answer and solution,"
          highlightedText="they trust us to be absolutely open with them at every step of the process."
        />
        <Arrow url="about-3"  alignBottom={false}/>
        <div className="my-about-title">
          <a href={IG_URL} target="_blank"><h2 id="about-3" className="my-about__title" >Gallery</h2></a>
          <div className="my-about-title__underscore" />
        </div>
        <Gallery style={{marginTop: "100px"}} images={images} />
      </Layout>
    </main>
  )
}

export default AboutPage;
